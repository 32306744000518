<template>
  <ElFormItem
    :label="locale.label"
    :prop="field"
    :errors="errors[field]"
  >
    <ElInput
      class="view-pixels__input"
      v-for="item in links"
      :key="item.id"
      :placeholder="locale.placeholder"
      v-model="item.value"
    />

    <PlusButton
      @click="addItem"
      :disabled="disabledAddButton"
    >
      {{ locale.addLabel }}
    </PlusButton>
  </ElFormItem>
</template>

<script>
import PlusButton from 'components/buttons/PlusButton'
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'

export default {
  components: {
    ElFormItem,
    ElInput,
    PlusButton,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  data: () => ({
    field: 'pixel_impressions',
  }),

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('form.data.fields.pixelImpressions.label'),
      placeholder: $locale('form.data.fields.pixelImpressions.placeholder'),
      addLabel: $locale('form.data.fields.pixelImpressions.addLabel'),
    }),
    links: ({ model, field }) => model[field],
    errors: ({ $store }) => $store.getters['creative/errors'],

    disabledAddButton ({ links }) {
      if (links.find(item => item.value === '')) return true

      const uniqueValues = []
      let hasSameValues = false

      links.map(item => {
        if (uniqueValues.includes(item.value)) {
          hasSameValues = true
        }
        else {
          uniqueValues.push(item.value)
        }
      })

      return hasSameValues
    },
  },

  methods: {
    addItem () {
      this.model[this.field].push({
        id: new Date().getTime(),
        value: '',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.view-pixels {
  &__input {
    margin-bottom: 15px;
  }
}
</style>
